import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Admin = () => {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [logout, setLogout] = useState(false);
  const [managers, setManagers] = useState([]);
  const [credits, setCredits] = useState("");
  const [creditsUpdate, setCreditsUpdate] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const handleLogout = async (e) => {
   
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (!confirmLogout) {
      return;
    }
    e.preventDefault();
    localStorage.removeItem("user");
    try {
      const response = await axiosPrivate.get("/logout");
      
      setLogout(true);
    } finally {
      navigate("/login");
    }
  };

  useEffect(() => {
    const getManagers = async () => {
      try {
        const response = await axiosPrivate.get("/admin/getmanagers");
      
        setManagers(response?.data);
      } catch (err) {
        console.error(err);
        setErrMsg(err?.response?.data?.message || err?.message);
      }
    };
    getManagers();
  }, []);

  const handleUpdateCredits = async (username) => {
    const credits = prompt("Enter manger credits");
    try {
      const response = await axiosPrivate.post(
        "/admin/updatecredits",
        JSON.stringify({ manager: username, newcredits: credits })
      );
      
      setCreditsUpdate(true);
      // alert("Credits updated successfully");
      setCredits("");

      // update setmanager with new credits
      setManagers((prev) =>
        prev.map((manager) => {
          if (manager.user === username) {
            return {
              ...manager,
              credits,
            };
          }
          return manager;
        })
      );
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 404) {
        setErrMsg("No matching manager found to update credits");
      } else if (err?.response?.status === 400) {
        setErrMsg("Missing or invalid parameters");
      } else if (err?.response?.status === 401) {
        setErrMsg("Invalid or missing authorization token");
      } else if (err?.response?.status === 500) {
        setErrMsg("Failed to update credits due to an internal error");
      } else {
        setErrMsg(err?.response?.data?.message || err?.message);
      }
      alert(errMsg);
    }
  };

  const handleDeleteManager = async (username) => {
    try {
  
      const confirmDelete = window.confirm(`Are you sure you want to delete the manager ${username}?`);
      if (!confirmDelete) {
        return;
      }


      const response = await axiosPrivate.post(
        "/admin/deletemanager",
        JSON.stringify({ manager: username })
      );
   
      // alert("Manager deleted successfully");
      setManagers((prev) => prev.filter((manager) => manager.user !== username));

      
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 404) {
        setErrMsg("No matching manager found to delete");
      } else if (err?.response?.status === 400) {
        setErrMsg("Missing or invalid parameters");
      } else if (err?.response?.status === 401) {
        setErrMsg("Invalid or missing authorization token");
      } else if (err?.response?.status === 500) {
        setErrMsg("Failed to delete manager(s) due to an internal error");
      } else {
        setErrMsg(err?.response?.data?.message || err?.message);
      }
      alert(errMsg);
    }
  };

  return (
    <section className="p-8 lg:w-6/7">
      <h1 className="text-3xl font-bold mb-4">Admin Dashboard</h1>
      <p className="mb-4">Welcome to the Admin Dashboard</p>
      <div>
        <h1 className="text-3xl font-bold mb-4">Managers</h1>
        {managers && (
          <table className="table-auto  items-center w-full">
            <thead className="bg-gray-600 text-gray-100"  style={{backgroundColor:'#173119f1',}}>
              <tr>
                <th className="px-4 py-2">Username</th>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Credits</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {managers.map((manager) => (
                <tr key={manager.user}>
                  <td className="border px-4 py-2">{manager.user}</td>
                  <td className="border px-4 py-2">{manager.name}</td>
                  <td className="border px-4 py-2">{manager.credits}</td>
                  <td className="border px-4 py-2">
                    <button
                      className=" newbtn font-bold"
                      style={{width:'12rem',marginBottom:'0.5rem',}}
                      onClick={() => handleUpdateCredits(manager.user)}
                    >
                      Update Credits
                    </button>
                    <br />
                    <button
                      className=" newbtn1 font-bold"
                      style={{width:'12rem',marginBottom:'0.5rem',}}
                      onClick={() => {
                        handleDeleteManager(manager.user);
                      }}
                    >
                      Delete Manager
                    </button>
                    <br />
                    <button
                      className="newbtn font-bold"
                      style={{width:'12rem',marginBottom:'0.5rem',
                     
                      }}

                      onClick={() => {
                        localStorage.setItem("manager", manager.user);
                        navigate(`/admin/getquizzes/?manager=${manager.user}`);
                      }}
                    >
                      Get Quizzes
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <div className="flex flex-wrap mt-3 pt-1 mb-1">
        <button
          className="bg-green-900 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-xl "
          style={{width:'29rem',}}
          onClick={() => navigate("/admin/register")}
        >
          Register New Manager
        </button>
      </div>

      <button
        className="bg-green-900 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-xl"
        style={{width:'29rem',}}
        onClick={handleLogout}
      >
        Logout
      </button>
    </section>
  );
};

export default Admin;
