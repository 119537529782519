// View Participant's Quiz Response
// Endpoint: POST /manager/viewresponse
// Description:
// This route allows managers to view the detailed responses of a participant in a quiz session. It requires providing the encrypted quiz ID and the participant's email in the request body. Additionally, it requires the Authorization header with a valid access token.

// Headers:
// Authorization (string, required): Bearer token containing a valid access token.
// Body Parameters:
// quizid (string, required): Encrypted ID of the quiz session.
// user (string, required): Email of the participant whose responses are to be viewed.
// Responses:
// 200 OK: Successfully retrieved and formatted participant responses.
// 400 Bad Request: Invalid quiz type for this operation or participant has not attempted the quiz.
// 401 Unauthorized: Invalid or missing authorization token.
// 404 Not Found: Quiz not found or unauthorized access.
// 500 Internal Server Error: Failed to retrieve responses due to an internal error.
// Example Request:
// POST /manager/viewresponse
// {
//   "quizid": "encryptedQuizIdHere",
//   "user": "participantEmailHere"
// }
// Example Response (Success):
// {
//   "score": [75, 80, 60, 90],
//   "participantDetails": {
//     "name": "Participant Name",
//     "email": "participant@example.com",
//     "phone": "1234567890",
//     "age": 25,
//     "sex": "female"
//   },
//   "questions": [
//     {
//       "questionText": "Question 1 Text?",
//       "options": [
//         { "optionText": "Option A", "character": "A", "response": 3 },
//         { "optionText": "Option B", "character": "B", "response": 2 },
//         { "optionText": "Option C", "character": "C", "response": 4 },
//         { "optionText": "Option D", "character": "D", "response": 1 }
//       ]
//     }
//     // Add more questions if needed
//   ]
// }
// the quizid and user are taken link params

import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useLocation } from "react-router-dom";

const ViewResponse = () => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const [responseDetails, setResponseDetails] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchResponseDetails = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const quizid = searchParams.get("quizid");
        const participantEmail = searchParams.get("user");

       

        const response = await axiosPrivate.post(
          "/manager/viewresponse",
          JSON.stringify({
            quizid,
            user: participantEmail,
          })
        );
   
        setResponseDetails(response.data);
        setError("");
      } catch (err) {
        console.error(err);
        if (err?.response?.data?.message) {
          setError(err.response.data.message);
        } else {
          setError("Failed to fetch response details");
        }
        setResponseDetails(null);
      }
    };

    fetchResponseDetails();
  }, [axiosPrivate]);

  return (
    <div className="bg-white min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-4 text-black lg:w-3/6">
      <div className="max-w-md w-full space-y-8">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}
        {responseDetails && (
          <div>
            <h1 className="text-3xl font-bold text-center pb-5">
              Participant Responses
            </h1>
            <div>
              <h2 className="text-lg font-semibold mb-2 pt-4">
                Participant Details
              </h2>
              <p>Name: {responseDetails.participantDetails.name}</p>
              <p>Email: {responseDetails.participantDetails.email}</p>
              <p>Phone: {responseDetails.participantDetails.phone}</p>
              <p>Age: {responseDetails.participantDetails.age}</p>
              <p>Sex: {responseDetails.participantDetails.sex}</p>
            </div>
            <div>
              <h2 className="text-lg font-semibold mt-4 mb-2">Score</h2>
              <ul>
                {/* {responseDetails.score.map((score, index) => (
                  <li key={index}>Question {index + 1}: {score}</li>
                ))} */}
                <li>F = {responseDetails.score[0]}%</li>
                <li>A = {responseDetails.score[1]}%</li>
                <li>C = {responseDetails.score[2]}%</li>
                <li>E = {responseDetails.score[3]}%</li>
              </ul>
            </div>
            <div>
              <h2 className="text-lg font-semibold mt-4 pt-4 mb-2">
                Questions & Responses
              </h2>
            
              {responseDetails.questions.map((question, index) => (
                <div key={index}>
                  <h3 className="text-base font-semibold mt-3">
                    {question.questionText}
                  </h3>
                  
                  <ul>
                    {question.options.map((option, optionIndex) => (
                      <li key={optionIndex}>
                        {option.optionText}:{" "}
                        <span className="text-green-500 font-semibold">
                          {option.response}{" "}
                        </span>
                      </li>
                      
                    ))}
                   
                  </ul>
                  <hr className="mt-4 mb-4 block  border-t border-gray-400" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewResponse;
