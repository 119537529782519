// Get Quiz Details for a Manager
// Endpoint: POST /admin/getquiz
// Description:
// This route allows administrators to retrieve detailed information about a specific quiz associated with a manager. It requires providing the quiz ID and manager's username in the request body. Additionally, it requires the Authorization header with a valid access token.

// Headers:
// Authorization (string, required): Bearer token containing a valid access token.
// Body Parameters:
// quizid (string, required): ID of the quiz for which details are to be retrieved.
// manager (string, required): Username of the manager associated with the quiz.
// Responses:
// 200 OK: Successfully retrieved the details of the specified quiz.
// 404 Not Found: Manager not found or unauthorized access, or the specified quiz not found.
// 401 Unauthorized: Invalid or missing authorization token.
// 500 Internal Server Error: Failed to retrieve quiz details due to an internal error.
// Example Request:
// POST /admin/getquiz
// {
//   "quizid": "encryptedQuizIdHere",
//   "manager": "managerUsernameHere"
// }
// Example Response (Success):
// {
//   "quizDetails": {
//     "quizid": "encryptedQuizIdHere",
//     "quizname": "Quiz Name",
//     "quiztype": "FaceQuiz",
//     "creditlimit": 100,
//     "status": "active",
//     "participants": [
//       {
//         "user": "participantUsername1",
//         "name": "Participant Name 1",
//         "scores": [75, 80, 90, 85]
//       },
//       {
//         "user": "participantUsername2",
//         "name": "Participant Name 2",
//         "scores": ["NA", 95, 88, "NA"]
//       }
//       // more participants
//     ]
//   }
// }

//take  quizid and manager from url params

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Table } from "react-bootstrap";


const GetQuizOfManager = () => {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    
    const [quizDetails, setQuizDetails] = useState({});
    const [errMsg, setErrMsg] = useState("");
    const [success, setSuccess] = useState(false);
    
    useEffect(() => {
        const fetchQuizDetails = async () => {
        try {
            // take manager username from url params
            const searchParams = new URLSearchParams(location.search);
            const manager = searchParams.get("manager");
            const quizid = searchParams.get("quizid");
           
    
            const response = await axiosPrivate.post(
            "/admin/getquiz",
            JSON.stringify({
                quizid,
                manager,
            })
            );
          
            setSuccess(true);
            setQuizDetails(response?.data?.quizDetails);
        } catch (err) {
            if (err?.response?.status === 404) {
            setErrMsg(
                "Manager not found or unauthorized access, or the specified quiz not found."
            );
            } else if (err?.response?.status === 401) {
            setErrMsg("Invalid or missing authorization token.");
            } else {
            setErrMsg(
                "Failed to retrieve quiz details due to an internal error."
            );
            }
        }
        };
        fetchQuizDetails();
    }, []);
    
    return (
        <section className="p-8">
        <h1 className="text-3xl font-bold mb-4">Quiz Details</h1>
        {errMsg && (
            <div className="bg-red-100 text-red-700 py-2 px-4 mb-4">{errMsg}</div>
        )}
         {/* {"quizDetails":{"quizid":"6592a53bbb8a3a7e4add1ca8","quizname":"Quiz2","quiztype":"FaceQuiz","creditlimit":20,"status":"start","participants":[{"user":"srdevcode@gmail.com","name":"Devan","scores":[62.5,61.25,62.5,63.75]},{"user":"srdevcode1@gmail.com","name":"NA","scores":["NA"]},{"user":"srdtvpm@gmail.com","name":"Devan1","scores":["NA"]}]}}  */}
            {/* the above is the response from the api. the page should display the quiz details in a table format.  */}
        *
        {success && (
            <div className=" text-white-700 py-4 px-6 mb-8 rounded-lg">
            <div className=" text-sm mb-4 items-start justify-start m-0  w-60">
                <p className="mb-2">
                <span className="font-bold mb-4">Quiz Name:</span>{" "}
                {quizDetails?.quizname}
                </p>
                <p className="mb-2">
                <span className="font-bold mb-4">Quiz Type:</span>{" "}
                {quizDetails?.quiztype}
                </p>
                <p className="mb-2">
                <span className="font-bold mb-4">Credit Limit:</span>{" "}
                {quizDetails?.creditlimit}
                </p>
                <p className="mb-2">
                <span className="font-bold mb-4">Status:</span>{" "}
                {quizDetails?.status}
                </p>
            </div>
            {quizDetails?.participants?.length > 0 && (
                <>
                <h1 className="text-2xl font-medium mb-4 pt-3">Participants</h1>
                <Table className="min-w-full">
                    <thead className="bg-gray-700 text-gray-100">
                    <tr>
                        <th className="px-4 py-2">S.No</th>
                        <th className="px-4 py-2">Username</th>
                        <th className="px-4 py-2">Name</th>
                        <th className="px-4 py-2">Scores</th>
                    </tr>
                    </thead>
                    <tbody>
                    {quizDetails?.participants?.map((participant, index) => (
                        <tr key={participant.user}>
                        <td className="border px-4 py-2">{index + 1}</td>
                        <td className="border px-4 py-2">{participant.user}</td>
                        <td className="border px-4 py-2">{participant.name}</td>
                        <td className="border px-4 py-2">
                        {participant.scores[0] == "NA" ? "Not Attempted" : (
                            <>
                            F={participant.scores[0] + " "}
                            A={participant.scores[1] + " "}
                            C={participant.scores[2] + " "} 
                            E={participant.scores[3] + " "}

                            </>
                        )}
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                </>

            )}
            </div>
        )}
        <button
            className="hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-4"
            onClick={() => navigate("/admin/getquizzes")}
        >
            Back
        </button>
        </section>
    );
}

export default GetQuizOfManager;
