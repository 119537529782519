import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Table } from "react-bootstrap";

const QuizDetails = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const quizid = localStorage.getItem("quizid");
  const [quizDetails, setQuizDetails] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [registrationStatus, setRegistrationStatus] = useState("close");
  const [apiSuccessMsg, setApiSuccessMsg] = useState("");

  useEffect(() => {
    const fetchQuizDetails = async () => {
      try {
        const response = await axiosPrivate.post(
          "/manager/quizdetails",
          JSON.stringify({
            quizid,
          })
        );

        console.log("response", response);
        setSuccess(true);
        setQuizDetails(response?.data?.quizDetails);
        setRegistrationStatus(
          response?.data?.quizDetails?.regstatus === "no" ? "close" : "open"
        );
      } catch (err) {
        console.error(err);
        setErrMsg(err?.response?.data?.message || err?.message);
      }
    };
    fetchQuizDetails();
  }, [axiosPrivate, quizid]);

  const handleRemoveParticipant = async () => {
    try {
      const confirmRemove = window.confirm(
        "Are you sure you want to remove the selected participant(s)?"
      );

      if (!confirmRemove) {
        // If the user cancels the action, do nothing
        return;
      }

      //console.log("selectedParticipants", selectedParticipants);
      const users = selectedParticipants;
      const response = await axiosPrivate.post(
        "/manager/removeparticipant",
        JSON.stringify({
          quizid,
          users,
        })
      );

      // update the participants list
      setQuizDetails((prev) => ({
        ...prev,
        participants: prev.participants.filter(
          (participant) => !users.includes(participant.user)
        ),
      }));
      setSelectedParticipants([]);

      if (response.status === 200) {
        // Participant removed successfully
        console.log("Participant removed successfully");
        // You may want to update the local state or trigger a fetch for updated data
      } else {
        console.error("Failed to remove participant");
      }
    } catch (err) {
      console.error(err);

      setErrMsg(err?.response?.data?.message || err?.message);

      console.log("err", errMsg);
    }
  };

  // Update Quiz Registration Status
  // Endpoint: POST /api/manager/regstatus
  // Description:
  // This route allows managers to update the registration status (open or closed) for a specific quiz. It requires providing the quiz ID and the desired registration status in the request body. Additionally, it requires the Authorization header with a valid access token.

  // Headers:
  // Authorization (string, required): Bearer token containing a valid access token.
  // Body Parameters:
  // quizid (string, required): The unique identifier of the quiz for which registration status needs to be updated.
  // status (string, required): The desired registration status. Should be either "yes" to open registrations or "no" to close registrations.
  // Responses:
  // 200 OK: Successfully updated the registration status for the specified quiz.
  // 400 Bad Request: Invalid status value or missing required fields.
  // 404 Not Found: Quiz not found or unauthorized access.
  // 401 Unauthorized: Invalid or missing authorization token.
  // 500 Internal Server Error: Failed to update registration status due to an internal error.
  // Example Request:
  // POST /api/manager/regstatus
  // {
  //   "quizid": "uniqueQuizIdHere",
  //   "status": "yes"
  // }
  // Example Response:
  // {
  //   "message": "Registrations opened successfully",
  //   "quizId": "uniqueQuizIdHere",
  //   "status": "yes"
  // }

  const handleChangeRegistrationStatus = async () => {
    try {
      const confirmChange = window.confirm(
        "Are you sure you want to change the registration status of the quiz?"
      );
      if (!confirmChange) {
        // If the user cancels the action, do nothing
        return;
      }
      const status = registrationStatus === "close" ? "yes" : "no";
      const response = await axiosPrivate.post(
        "/manager/regstatus",
        JSON.stringify({
          quizid,
          status,
        })
      );

      // update the registration status
      setRegistrationStatus(status);

      //update the quiz details
      setQuizDetails((prev) => ({
        ...prev,
        regstatus: status,
      }));

      if (response.status === 200) {
        // alert("Registration status updated successfully");
      }
    } catch (err) {
      //console.error(err);
      if (err?.response?.status === 400) {
        setErrMsg("Invalid status value or missing required fields.");
      } else if (err?.response?.status === 401) {
        setErrMsg("Invalid or missing authorization token.");
      } else if (err?.response?.status === 404) {
        setErrMsg("Quiz not found or unauthorized access.");
      } else {
        setErrMsg(
          "Failed to update registration status due to an internal error."
        );
      }
      alert(errMsg);
    }
  };
  const handleSendAllScores = async () => {
    try {
      const confirmChange = window.confirm(
        "Are you sure you want to send scores to all participants?"
      );
      if (!confirmChange) {
        // If the user cancels the action, do nothing
        return;
      }

      const response = await axiosPrivate.post(
        "/manager/sendallscores",
        JSON.stringify({
          quizid,
        })
      );

      if (response.status === 200) {
        setApiSuccessMsg(response.data.message);
      }
    } catch (err) {
      //console.error(err);
      if (err?.response?.data?.error) {
        setErrMsg(err.response.data.error);
      } else {
        setErrMsg("Failed to send scores due to an internal error.");
      }
    }
  };

  return (
    <section className="p-8">
      <h1 className="text-3xl font-bold mb-4">Quiz Details</h1>
      {apiSuccessMsg && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
          {apiSuccessMsg}
        </div>
      )}
      {errMsg && (
        <div className="bg-red-100 text-red-700 py-2 px-4 mb-4">{errMsg}</div>
      )}
      {success && (
        <div className=" text-white-700 py-4 px-6 mb-8 rounded-lg">
          <div className=" text-sm mb-4 items-start justify-start m-0  w-60">
            <p className="mb-2">
              <span className="font-bold">Quiz Code:</span> {quizDetails?.code}
            </p>
            <p className="mb-2">
              <span className="font-bold">Quiz Name:</span>{" "}
              {quizDetails?.quizname}
            </p>
            <p className="mb-2">
              <span className="font-bold ">Quiz Type:</span>{" "}
              {quizDetails?.quiztype}
            </p>
            <p className="mb-2">
              <span className="font-bold ">Credit Limit:</span>{" "}
              {quizDetails?.creditlimit}
            </p>
            <p className="mb-2">
              <span className="font-bold">Status:</span> {quizDetails?.status}
            </p>
            <p className="mb-2">
              <span className="font-bold ">Registration Status:</span>{" "}
              {registrationStatus}
            </p>
          </div>
          {quizDetails?.participants?.length > 0 && (
            <div className="mt-8">
              <h4 className="font-bold text-lg mb-4">Participants</h4>
              <Table className="w-full">
                <thead
                  className="text-gray-100"
                  style={{ backgroundColor: "#173119f1" }}
                >
                  <tr
                    className=" text-gray-100"
                    style={{ backgroundColor: "#173119f1" }}
                  >
                    <th className="px-4 py-2">Name</th>
                    <th className="px-4 py-2">Email</th>
                    <th className="px-4 py-2">Scores</th>
                    <th className="px-4 py-2">Action</th>
                    <th className="px-4 py-2">Select</th>
                  </tr>
                </thead>
                <tbody>
                  {quizDetails?.participants?.map((participant) => (
                    <tr key={participant.user}>
                      <td className="border px-4 py-2">{participant.name}</td>
                      <td className="border px-4 py-2">{participant.user}</td>
                      <td className="border px-4 py-2">
                        {participant.scores[0] === "NA"
                          ? "Not Attempted"
                          : `F=${participant.scores[0]}
                      A=${participant.scores[1]} 
                      C=${participant.scores[2]} 
                      E=${participant.scores[3]}`}
                      </td>

                      {participant.scores[0] == "NA" ? (
                        <td className="border px-4 py-2 ">
                          <button
                            className=" text-white font-bold py-2 px-4 mr-1 newbtn "
                            style={{ marginBottom: "0.5rem" }}
                            onClick={() => {
                              localStorage.setItem("user", participant?.user);
                              navigate("/manager/viewparticipanturl");
                            }}
                          >
                            View URL
                          </button>
                          <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 newbtn"
                            onClick={() => {
                              localStorage.setItem("user", participant?.user);
                              navigate("/manager/sendparticipanturl");
                            }}
                          >
                            Send URL
                          </button>
                        </td>
                      ) : (
                        <td className="border px-4 py-2">
                          <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-1 newbtn"
                            onClick={() => {
                              localStorage.setItem("user", participant?.user);
                              navigate(
                                `/manager/viewresponse?quizid=${quizid}&user=${participant?.user} `
                              );
                            }}
                          >
                            View Response
                          </button>
                          <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded newbtn"
                            style={{ marginTop: "1rem" }}
                            onClick={() => {
                              localStorage.setItem("user", participant?.user);
                              navigate(
                                `/manager/sendresponse?quizid=${quizid}&emails=${participant?.user} `
                              );
                            }}
                          >
                            Send Response to user
                          </button>
                        </td>
                      )}
                      <td className="border px-4 py-2">
                        <input
                          type="checkbox"
                          onChange={() => {
                            const isSelected = selectedParticipants.includes(
                              participant.user
                            );
                            if (isSelected) {
                              setSelectedParticipants((prev) =>
                                prev.filter(
                                  (email) => email !== participant.user
                                )
                              );
                            } else {
                              setSelectedParticipants((prev) => [
                                ...prev,
                                participant.user,
                              ]);
                            }
                          }}
                          checked={selectedParticipants.includes(
                            participant.user
                          )}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          <div className="mt-8">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4 newbtn "
              style={{ width: "29rem", marginBottom: "0.5rem" }}
              onClick={() => {
                localStorage.setItem("quizid", quizid);
                navigate("/manager/addparticipant");
              }}
            >
              Add Participant
            </button>
            <br />
            {selectedParticipants.length > 0 && (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4  newbtn1"
                style={{ width: "29rem", marginBottom: "0.5rem" }}
                onClick={handleRemoveParticipant}
              >
                Remove Selected Participant
              </button>
            )}
            <br />
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4 newbtn"
              style={{ width: "29rem", marginBottom: "0.5rem" }}
              onClick={() => {
                localStorage.setItem("quizid", quizid);
                navigate("/manager/setquizstatus");
              }}
            >
              Set Quiz Status
            </button>
            <br></br>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4 newbtn"
              style={{ width: "29rem", marginBottom: "0.5rem" }}
              onClick={handleChangeRegistrationStatus}
            >
              Change Registration Status
            </button>
            <br></br>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4 newbtn"
              style={{ width: "29rem", marginBottom: "0.5rem" }}
              onClick={handleSendAllScores}
            >
              Send Scores to All Participants
            </button>
          </div>
        </div>
      )}
      <button
        className="b text-white font-bold py-2 px-4 rounded "
        style={{ backgroundColor: "#173119f1" }}
        onClick={() => navigate("/manager")}
      >
        Back
      </button>
    </section>
  );
};

export default QuizDetails;
