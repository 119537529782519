// POST /participate/start
// Description:
// This route allows participants to start a quiz session. It requires providing the encrypted quiz ID and encrypted participant email in the request body. The route returns the quiz details, including questions, for the participant to answer.

// Body Parameters:
// encryptedQuizId (string, required): Encrypted ID of the quiz session.
// encryptedParticipantEmail (string, required): Encrypted email of the participant.
// Responses:
// 200 OK: Successfully started the quiz session. Returns quiz details including questions.
// 400 Bad Request: Invalid request or URL parameters. The participant might not be added to the quiz or has an incomplete profile.
// 500 Internal Server Error: Failed to start the quiz session due to an internal error.
// Example Request:
// POST /participate/start
// {
//   "encryptedQuizId": "encryptedQuizIdHere",
//   "encryptedParticipantEmail": "encryptedParticipantEmailHere"
// }
// Example Response (Success):
// {
//   "quizType": "FaceQuiz",
//   "questions": [
//     {
//       "questionText": "What is your favorite color?",
//       "options": ["Red", "Blue", "Green", "Other"]
//     }
//     // more questions
//   ]
// }
// call the /participate/start route in useEffect() and save the api response to state variable quizResponse






import React from 'react'
import { useEffect,useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { InfinitySpin } from 'react-loader-spinner'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'


const LoadingPage = () => {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [quizResponse, setQuizResponse] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    const encryptedQuizId = localStorage.getItem("quizid");
    const encryptedParticipantEmail = localStorage.getItem("participantEmail");
    console.log(encryptedQuizId)
    console.log(encryptedParticipantEmail)
  
    useEffect(() => {
      const fetchQuizDetails = async () => {
        try {
          const response = await axiosPrivate.post("/participate/start", {
            encryptedQuizId,
            encryptedParticipantEmail,
          });
   
            setQuizResponse(response.data);
            console.log(response);
            console.log(response.data.message)
            setError("");
            setLoading(false);

        } catch (err) {
          handleRequestError(err);
        }
      };
  
      fetchQuizDetails();
    }, [axiosPrivate, encryptedQuizId, encryptedParticipantEmail]);

    const handleRequestError = (err) => {
        console.error(err);
        if (err?.response?.status === 400) {
          setError("Invalid or missing parameters");
        } else if (err?.response?.status === 401) {
          setError("Invalid or missing authorization token");
        } else if (err?.response?.status === 500) {
          setError("Failed to start the quiz due to an internal error");
        } else {
          setError(err?.response?.data?.message || err?.message);
        }
      }



  return (
    <>
    {loading ? (
        <div className="flex bg-white w-full  h-screen">
        <div className="flex flex-col items-center justify-center w-full h-full">
            <InfinitySpin
            visible={true}
            width="150"
            color="black"
            ariaLabel="infinity-spin-loading"
            className=" justify-center items-center   w-full h-full"
            />
            <h3 className="text-black  ">Loading...</h3>
        </div>
        </div>
    ) : (
        <div className="flex bg-white w-full  h-screen">
        <div className="flex flex-col items-center justify-center w-full h-full">
            <h3 className="text-black  ">{error}</h3>
        </div>
        </div>
    )}
    </>
    )
}


export default LoadingPage